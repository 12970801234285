.App {
  text-align: center;
  height: 100vh;
}

* {
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: rotate(8deg);
  }

  25% {
    transform: rotate(-8deg);
  }

  50% {
    transform: rotate(8deg);
  }

  75% {
    transform: rotate(-8deg);
  }

  100% {
    transform: rotate(8deg);
  }
}

.MuiDialog-root .MuiBackdrop-root {
  backdrop-filter: blur(10px) brightness(0.8);
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}

input[type="color"] {
  border-radius: 5px;
  padding: 0;
  border: 5px solid #DDD;
}
input[type="color"]::-moz-color-swatch {
  border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}